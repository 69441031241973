import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'alpha',

  ecomm: {
    baseUrl: 'https://ecomm.alpha.us.regional.dev.cf.10piececombo.com',
    menuUrl: 'https://ecomm.alpha.us.regional.dev.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.alpha.us.regional.dev.cf.10piececombo.com'
  },
};
